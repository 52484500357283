<template>
  <div class="page events">
    <h1>{{ $t('events.title') }}</h1>
    <template v-if="!hasLocations">
      <p>{{ $t('events.no-locations') }}</p>
      <p>
        <router-link to="/locations">{{ $t('events.no-locations2') }}</router-link>
      </p>
    </template>
    <template v-else>
      <div>
        <button class="button button--accept" @click="newEvent">{{ $t('events.add-event') }} +</button>
        <a class="events__link" :href="`${publicPath}guestlist_template.xlsx`">{{ $t('events.download') }}</a> <sup
          @click="onHelpClicked">?</sup>
      </div>
      <div class="events__results">
        <teleport to="#app">
          <div class="modal" v-if="isLoading">
            <fa icon="spinner" class="loader"></fa>
          </div>
        </teleport>
        <table class="table" v-if="hasEvents">
          <thead>
          <tr class="table__header">
            <th class="table__header__column">{{ $t('image') }}</th>
            <th class="table__header__column">{{ $t('name') }}</th>
            <th class="table__header__column">{{ $t('location') }}</th>
            <th class="table__header__column">{{ $t('date-from') }}</th>
            <th class="table__header__column">{{ $t('time-from') }}</th>
            <th class="table__header__column">{{ $t('date-to') }}</th>
            <th class="table__header__column">{{ $t('time-to') }}</th>
            <th class="table__header__column">{{ $t('visible-start') }}</th>
            <th class="table__header__column">{{ $t('visible-end') }}</th>
            <th class="table__header__column">{{ $t('events.guests') }}</th>
            <th class="table__header__column">{{ $t('invoice') }}</th>
            <th class="table__header__column">{{ $t('actions') }}</th>
          </tr>
          </thead>
          <tbody class="table__body">
          <tr class="table__body__item" v-for="item in events" :key="item.id">
            <th class="table__header__column">
              <img v-if="item.foto" :src="item.foto" class="preview-image">
              <span v-else>/</span>
            </th>
            <td class="table__body__item__column">{{ item.naam }}</td>
            <td class="table__body__item__column">{{ getLocatie(item.locatie_id) }}</td>
            <td class="table__body__item__column">{{ item.datumheen }}</td>
            <td class="table__body__item__column">{{ item.uurheen }}</td>
            <td class="table__body__item__column">{{ item.datumterug }}</td>
            <td class="table__body__item__column">{{ item.uurterug }}</td>
            <td class="table__body__item__column">{{ item.zichtbaarvan }}</td>
            <td class="table__body__item__column">{{ item.zichtbaartot }}</td>
            <td class="table__body__item__column"><span class="clickable"
                                                        @click="showTravelers(item)">{{ item.aantalheen }} / {{ item.aantalterug }}</span>
            </td>
            <td class="table__body__item__column">{{ item.faktuur?'X':'' }}</td>
            <td class="table__body__item__column">
              <button v-if="item.faktuur" class="svgbutton info" :title="$t('events.add-list')" @click="addListEvent(item)">
                <fa icon="user-plus"></fa>
              </button>
              <button class="svgbutton info" :title="$t('events.edit-event')" @click="editEvent(item)">
                <fa icon="edit"></fa>
              </button>
              <button class="svgbutton info" :title="$t('events.duplicate-event')" @click="cloneEvent(item)">
                <fa icon="clone"></fa>
              </button>
              <button class="svgbutton close" :title="$t('events.delete-event')" @click="deleteEvent(item.id)">
                <fa icon="trash-alt"></fa>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="no_results">
          {{ $t('events.no-events') }}
        </div>
      </div>
      <teleport to="#app">
        <div class="modal events__modal" v-if="isModalActive">
          <div class="modal__wrapper" :class="{'editwrapper':!deleteId}">
            <button class="svgbutton modal__close error" @click="closeModal">
              <fa icon="times"></fa>
            </button>
            <template v-if="deleteId">
              <div class="modal__title">{{ $t('events.delete-title') }}</div>
              <div class="modal__body">{{ $t('events.delete-text') }}</div>
              <div class="modal__controls">
                <button class="button button--cancel" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click="deleteConfirm">{{ $t('delete') }}
                </button>
              </div>
            </template>
            <template v-else>
              <div class="modal__title">{{ event.id ? $t('events.edit-title') : $t('events.add-title') }}</div>
              <div class="modal__body">
                <form action="" @submit.prevent="saveEvent">
                  <div class="form__column">
                    <div class="form__field">
                      <label for="e-name">{{ $t('name') }}</label>
                      <input name="e-name" :placeholder="$t('name')" id="e-name" type="text" class="input"
                             v-model="event.naamevent">
                    </div>
                    <div class="checkbox" v-if="!event.id">
                      <input type="checkbox" v-model="allLocations"> Alle locaties
                    </div>
                    <div class="form__field" v-if="!allLocations">
                      <label for="e-loc">{{ $t('location') }}</label>
                      <select name="e-loc" id="e-loc" class="select" v-model="event.locatie_id">
                        <option v-for="item in locations" :value="item.id" :key="item.id">{{ item.naam }}</option>
                      </select>
                    </div>
                    <div class="form__field">
                      <label for="e-datvan">{{ $t('date-start') }}</label>
                      <input name="e-datvan" id="e-datvan" placeholder="dd/mm/yyyy" class="input" type="date"
                             v-model="event.datumstartevent">
                    </div>
                    <div class="form__field">
                      <label for="e-uurvan">{{ $t('time-start') }}</label>
                      <input name="e-uurvan" id="e-uurvan" placeholder="HH:MM" class="input" type="text"
                             v-model="event.uurstartevent">
                    </div>
                    <div class="form__field">
                      <label for="e-margevan">{{ $t('margin-start') }}</label>
                      <select name="e-margevan" id="e-margevan" class="select" v-model="event.margeaankomst">
                        <option value="0">0</option>
                        <option value="0.5">0.5</option>
                        <option value="1">1</option>
                        <option value="1.5">1.5</option>
                        <option value="2">2</option>
                        <option value="2.5">2.5</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div class="form__field">
                      <label for="e-dattot">{{ $t('date-end') }}</label>
                      <input name="e-dattot" id="e-dattot" placeholder="dd/mm/yyyy" class="input" type="date"
                             v-model="event.datumeindeevent">
                    </div>
                    <div class="form__field">
                      <label for="e-uurtot">{{ $t('time-end') }}</label>
                      <input name="e-uurtot" id="e-uurtot" placeholder="HH:MM" class="input" type="text"
                             v-model="event.uureindeevent">
                    </div>
                    <div class="form__field">
                      <label for="e-margevan">{{ $t('margin-end') }}</label>
                      <select name="e-margetot" id="e-margetot" class="select" v-model="event.margevertrek">
                        <option value="0">0</option>
                        <option value="0.5">0.5</option>
                        <option value="1">1</option>
                        <option value="1.5">1.5</option>
                        <option value="2">2</option>
                        <option value="2.5">2.5</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </div>
                  <div class="form__column">
                    <div class="form__field">
                      <label for="e-zbvan">{{ $t('visible-start') }}</label>
                      <input name="e-zbvan" id="e-zbvan" placeholder="dd/mm/yyyy" class="input" type="date"
                             v-model="event.zichtbaarvan">
                    </div>
                    <div class="form__field">
                      <label for="e-zbtot">{{ $t('visible-end') }}</label>
                      <input name="e-zbtot" id="e-zbtot" placeholder="dd/mm/yyyy" class="input" type="date"
                             v-model="event.zichtbaartot">
                    </div>

                    <div class="checkbox">
                      <input id="faktuur" type="checkbox" v-model="event.faktuur">
                      <label for="faktuur" class="checkbox__label">{{ $t('events.invoice') }}</label>
                    </div>

                    <p><b>{{ $t('luggage-allowed') }}</b></p>
                    <div class="checkbox">
                      <input id="koffers" type="checkbox" v-model="event.settings.bagage" value="KOFFERS">
                      <label for="koffers" class="checkbox__label">{{ $t('luggage.suitcases') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="kinderwagens" type="checkbox" v-model="event.settings.bagage" value="KINDERWAGENS">
                      <label for="kinderwagens" class="checkbox__label">{{ $t('luggage.strollers') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="kinderstoelen" type="checkbox" v-model="event.settings.bagage" value="KINDERSTOELEN">
                      <label for="kinderstoelen" class="checkbox__label">{{ $t('luggage.chairs') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="fietskoffers" type="checkbox" v-model="event.settings.bagage" value="FIETSKOFFERS">
                      <label for="fietskoffers" class="checkbox__label">{{ $t('luggage.bikecases') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="skis" type="checkbox" v-model="event.settings.bagage" value="SKIS">
                      <label for="skis" class="checkbox__label">{{ $t('luggage.skis') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="golfbags" type="checkbox" v-model="event.settings.bagage" value="GOLFBAGS">
                      <label for="golfbags" class="checkbox__label">{{ $t('luggage.golfbags') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="rolstoelen" type="checkbox" v-model="event.settings.bagage" value="ROLSTOELEN">
                      <label for="rolstoelen" class="checkbox__label">{{ $t('luggage.wheelchairs') }}</label>
                    </div>
                    <div class="checkbox">
                      <input id="rollators" type="checkbox" v-model="event.settings.bagage" value="ROLLATORS">
                      <label for="rollators" class="checkbox__label">{{ $t('luggage.rollators') }}</label>
                    </div>
                  </div>
                  <div class="form__column">
                    <div class="form__field">
                      <label for="e-foto">{{ $t('image') }} (url)</label>
                      <input name="e-foto" id="e-foto" type="text" class="input" v-model="event.foto">
                    </div>
                    <div class="form__field">
                      <label for="FOTO">{{ $t('image') }}</label>
                      <div>
                        <input type="file" accept="image/*" class="file-input" id="FOTO" name="FOTO"
                               @change="srcChanged($event)">
                        <div class="file-preview file-preview--image" v-if="fotoSource">
                          <img :src="fotoSource" alt="Event photo">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal__controls">
                <p class="error" v-if="modalError">{{ modalError }}</p>
                <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click.prevent="saveEvent">{{
                    $t('save')
                  }}
                </button>
              </div>
            </template>
          </div>
        </div>
        <div class="modal events__modal" v-if="isDuplicateActive">
          <div class="modal__wrapper">
            <button class="svgbutton modal__close error" @click="closeModal">
              <fa icon="times"></fa>
            </button>
            <div class="modal__title">{{ $t('events.duplicate-title') }}</div>
            <div class="modal__body">
              <h2>{{ event.naamevent }} - {{ event.datumstartevent }}</h2>
              <form @submit.prevent="saveEvent">
                <div class="form__column">
                  <div class="form__field">
                    <label for="e-duploc">{{ $t('location') }}</label>
                    <select name="e-duploc" id="e-duploc" class="select" v-model="event.locatie_id">
                      <option v-for="item in locations" :value="item.id" :key="item.id">{{ item.naam }}</option>
                    </select>
                  </div>
                  <div class="form__field">
                    <label for="e-uurdupvan">{{ $t('time-start') }}</label>
                    <input name="e-uurdupvan" id="e-uurdupvan" placeholder="HH:MM" class="input" type="text"
                           v-model="event.uurstartevent">
                  </div>
                  <div class="form__field">
                    <label for="e-uurduptot">{{ $t('time-end') }}</label>
                    <input name="e-uurduptot" id="e-uurduptot" placeholder="HH:MM" class="input" type="text"
                           v-model="event.uureindeevent">
                  </div>
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <p class="error" v-if="modalError">{{ modalError }}</p>
              <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click.prevent="saveEvent">{{
                  $t('save')
                }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal" v-if="isUploadActive">
          <div class="modal__wrapper">
            <button class="svgbutton modal__close error" @click="closeModal">
              <fa icon="times"></fa>
            </button>
            <div class="modal__title">{{ $t('events.add-list') }}</div>
            <div class="modal__body">
              <h2>{{ uploadEvent.naam }} - {{ uploadEvent.datumheen }}</h2>
              <div class="modal__loader" v-if="isLoading">
                <fa icon="spinner" class="loader"></fa>
              </div>
              <template v-else>
                <table class="table" v-if="uploadListResult">
                  <thead>
                  <tr class="table__header">
                    <th class="table__header__column" v-if="uploadListResult && modalError">errors</th>
                    <th class="table__header__column" v-if="uploadListResult && modalError">error description</th>
                    <th class="table__header__column">name</th>
                    <th class="table__header__column">firstname</th>
                    <th class="table__header__column">phone</th>
                    <th class="table__header__column">email</th>
                    <th class="table__header__column">street</th>
                    <th class="table__header__column">nr</th>
                    <th class="table__header__column">postal_code</th>
                    <th class="table__header__column">city</th>
                    <th class="table__header__column">country</th>
                    <th class="table__header__column">travelers</th>
                    <th class="table__header__column">suitcases</th>
                    <th class="table__header__column">wheelchairs</th>
                    <th class="table__header__column">child car seats</th>
                    <th class="table__header__column">strollers</th>
                    <th class="table__header__column">skis</th>
                    <th class="table__header__column">golfbags</th>
                    <th class="table__header__column">bike cases</th>
                    <th class="table__header__column">rollators</th>
                    <th class="table__header__column">pets</th>
                    <th class="table__header__column">po</th>
                    <th class="table__header__column">direction</th>
                    <th class="table__header__column">language</th>
                  </tr>
                  </thead>
                  <tbody class="table__body">
                  <tr class="table__body__item" :class="{'table__body__item--error':item['0errors']}"
                      v-for="(item,index) in uploadListResult.input" :key="'row'+index">
                    <td class="table__body__item__column" v-for="key in Object.keys(item)" :key="index+key">
                      <template v-if="key === '1error_desc' && item['0errors']">
                        <p v-for="error in Object.keys(item[key])" :key="index+error">
                          {{ `${error} => ${item[key][error]}` }}</p>
                      </template>
                      <template v-else>{{ item[key] }}</template>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <form @submit.prevent="saveEvent" v-else>
                  <div class="form__column">
                    <input type="file" accept="*.xlsx" class="file-input file-input--inline" id="INPUTFILE"
                           name="INPUTFILE"
                           @change="uploadSrcChanged($event)"> <span v-if="uploadList">{{ uploadList.name }}</span>
                  </div>
                </form>
              </template>
            </div>
            <div class="modal__controls">
              <p class="error" v-if="modalError">{{ modalError }}</p>
              <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click.prevent="runUploadList"
                      v-if="!uploadListResult && !isLoading">
                {{ $t('events.upload') }}
              </button>
              <button class="button button--accept" @click.prevent="retryUploadList"
                      v-if="uploadListResult && modalError">
                {{ $t('events.try-again') }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal" v-if="isTravelersActive">
          <div class="modal__wrapper">
            <button class="svgbutton modal__close error" @click="closeModal">
              <fa icon="times"></fa>
            </button>
            <div class="modal__title">{{ $t('events.guests') }}</div>
            <div class="modal__body">
              <table class="table" v-if="travelerList">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column">name</th>
                  <th class="table__header__column">firstname</th>
                  <th class="table__header__column">direction</th>
                  <th class="table__header__column">from</th>
                  <th class="table__header__column">to</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item" v-for="item in travelerList" :key="`traveler--${item.id}`">
                  <td class="table__body__item__column">{{ item.naam }}</td>
                  <td class="table__body__item__column">{{ item.voornaam }}</td>
                  <td class="table__body__item__column">{{ item.richting }}</td>
                  <td class="table__body__item__column">{{ item.van }}</td>
                  <td class="table__body__item__column">{{ item.naar }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal__controls">
            </div>
          </div>
        </div>
        <div class="modal" v-if="helpActive">
          <div class="modal__wrapper">
            <button class="svgbutton modal__close error" @click="helpActive = false">
              <fa icon="times"></fa>
            </button>
            <div class="modal__title">Upload template help</div>
            <div class="modal__body">
              <ol>
                <li>Download the template by clicking on the <a
                    :href="`${publicPath}guestlist_template.xlsx`">{{ $t('events.download') }}</a> link.
                </li>
                <li>Open the template in Excel.</li>
                <li>Fill in the data, using the info underneath this list. (important note: we handle a <b>max cargo of
                  16 for each guest</b>, check the cargo-values in the list provided underneath)
                </li>
                <li>Upload the file, using the action-button
                  <button class="svgbutton info">
                    <fa icon="user-plus"></fa>
                  </button>
                  for the event you wish to add the guest list to.
                </li>
                <li>If your file contains invalid input, an error will be shown for the specific rows, no data will be
                  uploaded at all until all data provided is correct. Adjust your file and try again.
                </li>
              </ol>
              <table class="table">
                <thead>
                <tr class="table__header">
                  <th class="table__header__column events__helpcolumn">name</th>
                  <th class="table__header__column events__helpcolumn">firstname</th>
                  <th class="table__header__column events__helpcolumn">phone</th>
                  <th class="table__header__column events__helpcolumn">email</th>
                  <th class="table__header__column events__helpcolumn">street</th>
                  <th class="table__header__column events__helpcolumn">nr</th>
                  <th class="table__header__column events__helpcolumn">postal_code</th>
                  <th class="table__header__column events__helpcolumn">city</th>
                  <th class="table__header__column events__helpcolumn">country</th>
                  <th class="table__header__column events__helpcolumn">travelers</th>
                  <th class="table__header__column events__helpcolumn">suitcases</th>
                  <th class="table__header__column events__helpcolumn">wheelchairs</th>
                  <th class="table__header__column events__helpcolumn">child_car_seats</th>
                  <th class="table__header__column events__helpcolumn">strollers</th>
                  <th class="table__header__column events__helpcolumn">skis</th>
                  <th class="table__header__column events__helpcolumn">golfbags</th>
                  <th class="table__header__column events__helpcolumn">bike_cases</th>
                  <th class="table__header__column events__helpcolumn">rollators</th>
                  <th class="table__header__column events__helpcolumn">pets</th>
                  <th class="table__header__column events__helpcolumn">po</th>
                  <th class="table__header__column events__helpcolumn">direction</th>
                  <th class="table__header__column events__helpcolumn">language</th>
                </tr>
                </thead>
                <tbody class="table__body">
                <tr class="table__body__item">
                  <td class="table__body__item__column events__helpcolumn">Traveler's name</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's firstname</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's phone</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's email</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's street</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's street nr</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's postal code</td>
                  <td class="table__body__item__column events__helpcolumn">Traveler's city</td>
                  <td class="table__body__item__column events__helpcolumn">2digit iso code capitalised e.g. "BE", "FR",
                    "EN"...
                  </td>
                  <td class="table__body__item__column events__helpcolumn">people amount, max 8 <br>cargo-value: 1</td>
                  <td class="table__body__item__column events__helpcolumn">amount, max 2 <br>cargo-value: 1</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 5</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 0</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 2</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 2</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 2</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 5</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 5</td>
                  <td class="table__body__item__column events__helpcolumn">amount <br>cargo-value: 1 (contact us)</td>
                  <td class="table__body__item__column events__helpcolumn">po nr provided by organiser/agent</td>
                  <td class="table__body__item__column events__helpcolumn">H => only transport TO event<br>T => only
                    transport FROM event<br>HT => transport TO and FROM event
                  </td>
                  <td class="table__body__item__column events__helpcolumn">2digit iso code capitalised e.g. "BE", "FR",
                    "EN"...
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal__controls">
            </div>
          </div>
        </div>
      </teleport>
    </template>
  </div>
</template>

<script>
export default {
  name: "Events",

  components: {},

  data() {
    return {
      publicPath: process.env.BASE_URL,
      error: null,
      modalError: null,
      isLoading: false,
      isModalActive: false,
      isDuplicateActive: false,
      deleteId: null,
      isUploadActive: false,
      uploadListResult: null,
      uploadEvent: null,
      uploadList: null,
      fotoSource: null,
      uploadFoto: null,
      isTravelersActive: false,
      travelerList: null,
      helpActive: false,
      allLocations: false,
      event: {
        naamevent: "",
        locatie_id: "",
        datumstartevent: "",
        datumeindeevent: "",
        uurstartevent: "",
        uureindeevent: "",
        zichtbaarvan: "",
        zichtbaartot: "",
        margeaankomst: "0.5",
        margevertrek: "0.5",
        faktuur: 0,
        foto: "",
        settings: {
          bagage: []
        }
      }
    }
  },

  created() {
    this.loadLocations();
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },
    hasLocations() {
      return this.$store.getters['locations/hasLocations'];
    },
    events() {
      return this.$store.getters['events/events'];
    },
    hasEvents() {
      return this.$store.getters['events/hasEvents'];
    }
  },

  methods: {
    checkDate(input) {
      const testInput = this.formatDateSql(input);
      const regex = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
      return testInput.match(regex);
    },

    checkTIme(input) {
      const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return input.match(regex);
    },

    getLocatie(id) {
      const loc = this.locations.find(l => parseInt(l.id) === parseInt(id));
      return loc ? loc.naam : this.$t('unknown');
    },
    async loadLocations() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/loadLocations');
      } catch (error) {
        this.error = error.message || this.$t('error.general');
      }
      this.isLoading = false;
      this.loadEvents();
    },
    async loadEvents() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('events/loadEvents');
      } catch (error) {
        this.error = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },

    newEvent() {
      this.event = {
        naamevent: "",
        locatie_id: "",
        datumstartevent: "",
        datumeindeevent: "",
        uurstartevent: "",
        uureindeevent: "",
        zichtbaarvan: "",
        zichtbaartot: "",
        margeaankomst: "0.5",
        margevertrek: "0.5",
        faktuur: 0,
        foto: "",
        settings: {
          bagage: []
        }
      }
      this.allLocations = false;
      this.isModalActive = true;
    },

    editEvent(item) {
      this.allLocations = false;
      this.event = {
        id: item.id,
        naamevent: item.naam,
        locatie_id: item.locatie_id,
        datumstartevent: this.formatDateSql(item.datumheen).replaceAll('/','-'),
        datumeindeevent: this.formatDateSql(item.datumterug).replaceAll('/','-'),
        uurstartevent: item.uurheen,
        uureindeevent: item.uurterug,
        zichtbaarvan: this.formatDateSql(item.zichtbaarvan).replaceAll('/','-'),
        zichtbaartot: this.formatDateSql(item.zichtbaartot).replaceAll('/','-'),
        margeaankomst: item.margeaankomst,
        margevertrek: item.margevertrek,
        faktuur: !!item.faktuur,
        settings: item.settings ? JSON.parse(item.settings) : {},
        foto: item.foto
      }
      this.fotoSource = item.foto;
      if (!this.event.settings.bagage) {
        this.event.settings = {
          bagage: []
        }
      }
      this.isModalActive = true;
    },

    cloneEvent(item) {
      this.event = {
        naamevent: item.naam,
        locatie_id: item.locatie_id,
        datumstartevent: this.formatDateSql(item.datumheen).replaceAll('/','-'),
        datumeindeevent: this.formatDateSql(item.datumterug).replaceAll('/','-'),
        uurstartevent: item.uurheen,
        uureindeevent: item.uurterug,
        zichtbaarvan: this.formatDateSql(item.zichtbaarvan).replaceAll('/','-'),
        zichtbaartot: this.formatDateSql(item.zichtbaartot).replaceAll('/','-'),
        margeaankomst: item.margeaankomst,
        margevertrek: item.margevertrek,
        faktuur: !!item.faktuur,
        settings: item.settings ? JSON.parse(item.settings) : {},
        foto: item.foto
      }
      this.isDuplicateActive = true;
    },

    deleteEvent(id) {
      this.deleteId = id;
      this.isModalActive = true;
    },

    addListEvent(item) {
      this.uploadEvent = item;
      this.uploadList = null;
      this.isUploadActive = true;
      this.uploadListResult = null;
    },

    async runUploadList() {
      if (!this.uploadList) {
        return;
      }
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('events/importEventGuestList', {
          INPUTFILE: this.uploadList,
          id: this.uploadEvent.id
        });
        console.log(response);
        this.uploadListResult = response;
        if (response.input.some(i => i["0errors"])) {
          this.modalError = this.$t('error.invalid');
        } else {
          this.loadEvents();
        }
      } catch (error) {
        this.modalError = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },

    retryUploadList() {
      this.uploadList = null;
      this.uploadListResult = null;
      this.modalError = null;
    },

    async deleteConfirm() {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('events/deleteEvent', {id: this.deleteId});
        this.closeModal();
        this.loadEvents();
      } catch (error) {
        this.modalError = error.message || this.$t('error.events.delete');
        this.isLoading = false;
      }
    },

    saveEvent() {
      const ev = {...this.event};
      if (Object.keys(ev).some(key => (key !== 'foto' && key !== 'locatie_id' && ev[key] === "") || (key === 'locatie_id' && ev[key] === "" && !this.allLocations))) {
        this.modalError = this.$t('error.incomplete');
        return;
      }
      if (!this.checkDate(ev.datumstartevent.replaceAll('-','/'))
          || !this.checkDate(ev.datumeindeevent.replaceAll('-','/'))
          || !this.checkDate(ev.zichtbaarvan.replaceAll('-','/'))
          || !this.checkDate(ev.zichtbaartot.replaceAll('-','/'))
          || !this.checkTIme(ev.uurstartevent.replaceAll('-','/'))
          || !this.checkTIme(ev.uureindeevent.replaceAll('-','/'))) {
        this.modalError = this.$t('error.datetime');
        return;
      }
      ev.datumstartevent = ev.datumstartevent.replaceAll('-','/');
      ev.datumeindeevent = ev.datumeindeevent.replaceAll('-','/');
      ev.zichtbaarvan = ev.zichtbaarvan.replaceAll('-','/');
      ev.zichtbaartot = ev.zichtbaartot.replaceAll('-','/');
      ev.settings = JSON.stringify(ev.settings).replace("\\", "");
      if (this.uploadFoto) {
        ev.FOTO = this.uploadFoto;
      }
      if (ev.id) {
        this.saveEditEvent(ev);
        return;
      }
      this.saveNewEvent(ev);
    },
    async saveEditEvent(ev) {
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('events/editEvent', ev);
        this.closeModal();
        this.loadEvents();
      } catch (error) {
        this.modalError = error.message || this.$t('error.events.edit');
        this.isLoading = false;
      }
    },

    async saveNewEvent(ev) {
      this.modalError = null;
      this.isLoading = true;
      if(this.allLocations){
        const actions = [];
        this.locations.forEach( l => {
          const nev = {...ev};
          nev.locatie_id = l.id;
          actions.push(nev);
        });
        try{
          await Promise.all(actions.map(async (action) => {
            console.log(action);
            await this.$store.dispatch('events/addEvent', action);
          }));
          console.log('actions finished');
          this.closeModal();
          this.loadEvents();
        } catch(error){
          this.modalError = error.message || this.$t('error.events.save');
          this.isLoading = false;
        }
      }
      else {
        try {
          await this.$store.dispatch('events/addEvent', ev);
          this.closeModal();
          this.loadEvents();
        } catch (error) {
          this.modalError = error.message || this.$t('error.events.save');
          this.isLoading = false;
        }
      }
    },

    closeModal() {
      this.deleteId = null;
      this.isModalActive = false;
      this.isDuplicateActive = false;
      this.isUploadActive = false;
      this.isTravelersActive = false;
      this.modalError = null;
      this.fotoSource = null;
      this.uploadFoto = null;
      this.uploadList = null;
      this.travelerList = null;
      this.uploadEvent = null;
      this.uploadListResult = null;
      this.helpActive = false;
    },

    formatDateSql(date) {
      if (!date) {
        return "";
      }
      const parts = date.split('/');
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    },

    srcChanged(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadFoto = input.target.files[0];
          this.fotoSource = e.target.result;
          this.event.foto = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    uploadSrcChanged(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadList = input.target.files[0];
      }
    },

    async showTravelers(item) {
      const response = await this.$store.dispatch('events/listEventPassengers', item.id);
      if (response.length) {
        this.travelerList = response;
        this.isTravelersActive = true;
      }
    },

    onHelpClicked() {
      this.helpActive = true;
    }
  }
}
</script>