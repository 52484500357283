<template>
  <footer class="footer">
    Copyright Billo 2020 | version {{version}}
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      version: process.env.VERSION
    }
  }
}
</script>