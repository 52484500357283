export default {
    async loadEvents(context) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/leesevents/${userId}`;
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user event data.'
            );
            throw error;
        }
        let events = [];
        const dataEv = responseData.data;
        if(dataEv){
            events = dataEv;
        }
        context.commit('setEvents',events);
    },

    async addEvent(context, data) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        data.user_id=userId;
        const formData = new FormData();
        Object.keys(data).forEach( key => {
            formData.append(key, data[key]);
        });
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/addevent`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save new event.'
            );
            throw error;
        }
    },

    async editEvent(context,data) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        data.user_id=userId;
        const formData = new FormData();
        Object.keys(data).forEach( key => {
            formData.append(key, data[key]);
        });

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/editevent`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit event.'
            );
            throw error;
        }
    },

    async deleteEvent(context, data) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/delevent`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to delete event.'
            );
            throw error;
        }
    },

    async importEventGuestList(context, data){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        data.user_id=userId;
        const formData = new FormData();
        Object.keys(data).forEach( key => {
            formData.append(key, data[key]);
        });
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/importfile`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to import guestlist to event.'
            );
            throw error;
        }
        return responseData.data;
    },

    async listEventPassengers(context, data){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/listpassengers/${data}`;
        const response = await fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user event data.'
            );
            throw error;
        }
        return responseData.data;
    }
}