import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {createI18n} from 'vue-i18n';
import {languages, defaultLocale} from "@/i18n";
import { FontAwesomeIcon } from "@/plugins/font-awesome";

const messages = Object.assign(languages);
const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'nl',
    messages
});

createApp(App).use(store).use(router).use(i18n).component("fa", FontAwesomeIcon).mount('#app');
