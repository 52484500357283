import { createStore } from 'vuex'
import authModule from './modules/auth';
import locationsModule from './modules/locations';
import eventsModule from './modules/events';

export default createStore({
  modules: {
    auth: authModule,
    locations: locationsModule,
    events: eventsModule
  }
})
