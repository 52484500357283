import {createRouter, createWebHashHistory} from 'vue-router'
import Events from '../views/Events';
import Locations from '../views/Locations';
import Login from '../views/Login';
import store from '../store';

const routes = [
    {
        path: '/',
        redirect: '/events',
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: {requiresAuth: true}
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Locations,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {requiresUnauth: true}
    },
    {
        path: '/:notFound(.*)',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});

export default router
