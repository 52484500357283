<template>
  <div class="header">
    <div class="header__container">
      <div class="header__nav">
        <router-link to="/events">{{ $t('events.title') }}</router-link>
        <router-link to="/locations">{{ $t('locations.title') }}</router-link>
      </div>
      <div class="header__controls">
        <Language class="header__controls__language"></Language>
        <User class="header__controls__user"></User>
        <button class="header__controls__logout svgbutton logout" title="log out" @click="logout">
          <fa icon="power-off"></fa>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/User";
import Language from "@/components/Language";

export default {
  name: "TheHeader",
  components: {
    User,
    Language
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    }
  }
}
</script>