let timer;
export default {
    async login(context, payload) {
        return context.dispatch('auth', payload);
    },
    async auth(context, payload) {
        const url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'password',
                scope: 'openid',
                code: 'code',
                username: payload.username,
                password: payload.password,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.error_description || 'Failed to authenticate. Check your login data.'
            );
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        const dataLoginUrl = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/login`;
        const dataLoginResponse = await fetch(dataLoginUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ responseData.access_token,
            },
            body: JSON.stringify({

                user_name: payload.username,
                user_password: payload.password,

            })
        });

        const dataLoginResponseData = await dataLoginResponse.json();

        if (!dataLoginResponse.ok || !dataLoginResponseData.status) {
            const error2 = new Error(
                responseData.error_description || 'Failed to authenticate login. Check your login data.'
            );
            throw error2;
        }
        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('userName', payload.username);
        localStorage.setItem('userId', dataLoginResponseData.data.id);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId:  dataLoginResponseData.data.id,
            userName: payload.username
        });
    },

    async refreshToken(context) {
        const userId = localStorage.getItem('userId');
        const userName = localStorage.getItem('userName');
        const refreshtoken = localStorage.getItem('refreshToken');
        if(!refreshtoken || !userId || !userName){
            context.dispatch('autoLogout');
            return;
        }
        let url =
            `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_AUTH}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshtoken,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET
            })
        });
        const responseData = await response.json();

        if (!response.ok || responseData.error) {
            const error = new Error(
                responseData.message || 'Failed to re-authenticate.'
            );
            context.dispatch('autoLogout');
            throw error;
        }

        const expiresIn = +responseData.expires_in * 1000;
        const expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem('token', responseData.access_token);
        localStorage.setItem('refreshToken', responseData.refresh_token);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        context.commit('setUser', {
            token: responseData.access_token,
            userId: userId,
            userName: userName
        });
    },
    tryLogin(context) {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const userId = localStorage.getItem('userId');
        const userName= localStorage.getItem('userName');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if(!refreshToken){
            return;
        }

        if ((!expiresIn || expiresIn < 0) && refreshToken) {
            context.dispatch('refreshToken');
            return;
        }

        timer = setTimeout(function() {
            context.dispatch('refreshToken');
        }, expiresIn);

        if (token && userId) {
            context.commit('setUser', {
                token: token,
                userId: userId,
                userName: userName
            });
        }
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setUser', {
            token: null,
            userId: null,
            userName: null
        });
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    }
};