<template>
  <div class="page locations">
    <h1>{{ $t('locations.title') }}</h1>
    <div>
      <button class="button button--accept" @click="newLocation">{{ $t('locations.add-location') }} +</button>
    </div>
    <div class="locations__results">
      <teleport to="#app">
        <div class="modal" v-if="isLoading">
          <fa icon="spinner" class="loader"></fa>
        </div>
      </teleport>
      <table class="table" v-if="hasLocations">
        <thead>
        <tr class="table__header">
          <th class="table__header__column">{{ $t('name') }}</th>
          <th class="table__header__column">{{$t('street')}}</th>
          <th class="table__header__column">{{$t('nr')}}</th>
          <th class="table__header__column">{{$t('zip')}}</th>
          <th class="table__header__column">{{$t('city')}}</th>
          <th class="table__header__column">{{$t('actions')}}</th>
        </tr>
        </thead>
        <tbody class="table__body">
        <tr class="table__body__item" v-for="item in locations" :key="item.id">
          <td class="table__body__item__column">{{ item.naam }}</td>
          <td class="table__body__item__column">{{ item.straat }}</td>
          <td class="table__body__item__column">{{ item.nummer }}</td>
          <td class="table__body__item__column">{{ item.postnr }}</td>
          <td class="table__body__item__column">{{ item.stad }}</td>
          <td class="table__body__item__column">
            <button class="svgbutton info" :title="$t('locations.edit-location')" @click="editLocation(item)">
              <fa icon="edit"></fa>
            </button>
            <button class="svgbutton close" :title="$t('locations.delete-location')" @click="deleteLocation(item.id)">
              <fa icon="trash-alt"></fa>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no_results">
        {{$t('locations.no-locations')}}
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="isModalActive">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="closeModal">
            <fa icon="times"></fa>
          </button>
          <template v-if="deleteId">
            <div class="modal__title">{{ $t('locations.delete-title') }}</div>
            <div class="modal__body">{{ $t('locations.delete-text') }}</div>
            <div class="modal__controls">
              <button class="button button--cancel" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click="deleteConfirm">{{ $t('delete') }}</button>
            </div>
          </template>
          <template v-else>
          <div class="modal__title">{{ address.id ? $t('locations.edit-title') : $t('locations.add-title') }}</div>
          <div class="modal__body">
            <form action="" @submit.prevent="saveAddress">
              <div class="form__column">
              <div class="form__field">
                <label for="a-name">{{$t('name')}}</label>
                <input name="a-name" :placeholder="$t('name')" id="a-name" type="text" class="input" v-model="address.naam">
              </div>
              <div class="form__field">
                <label for="a-street">{{ $t('street') }}</label>
                <input name="a-street" :placeholder="$t('street')" id="a-street" type="text" class="input" v-model="address.straat">
              </div>
              <div class="form__field">
                <label for="a-nummer">{{ $t('nr') }}</label>
                <input name="a-nummer" :placeholder="$t('nr')" id="a-nummer" type="text" class="input" v-model="address.nummer">
              </div>
              <div class="form__field">
                <label for="a-postnr">{{ $t('zip') }}</label>
                <input name="a-postnr" :placeholder="$t('zip')" id="a-postnr" type="text" class="input" v-model="address.postnr">
              </div>
              <div class="form__field">
                <label for="a-stad">{{ $t('city') }}</label>
                <input name="a-stad" :placeholder="$t('city')" id="a-stad" type="text" class="input" v-model="address.stad">
              </div>
              </div>
            </form>
          </div>
            <div class="modal__controls">
              <p class="error" v-if="modalError">{{modalError}}</p>
              <button class="button button--accept" :class="{'button--disabled':isLoading}" :disabled="isLoading" @click.prevent="saveAddress">{{ $t('save') }}</button>
            </div>
          </template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: "Locations",

  data() {
    return {
      error: null,
      modalError:null,
      isLoading: false,
      isModalActive: false,
      deleteId: null,
      address: {
        naam: "",
        straat: "",
        nummer: "",
        postnr: "",
        stad: ""
      }
    }
  },

  created() {
    this.loadLocations();
  },
  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },
    hasLocations() {
      return this.$store.getters['locations/hasLocations'];
    },
    userData() {
      return this.$store.getters['locations/userData'];
    }
  },

  methods: {
    async loadLocations() {
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/loadLocations');

      } catch (error) {
        this.error = error.message || this.$t('error.general');
      }
      this.isLoading = false;
    },
    newLocation() {
      this.address = {
        naam: "",
        straat: "",
        nummer: "",
        postnr: "",
        stad: ""
      }
      this.isModalActive = true;
    },
    editLocation(item) {
      this.address = JSON.parse(JSON.stringify(item));
      this.isModalActive = true;
    },

    deleteLocation(id) {
      this.deleteId = id;
      this.isModalActive = true;
    },

    async deleteConfirm(){
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/deleteLocation',{id:this.deleteId});
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.delete');
        this.isLoading = false;
      }
    },

    saveAddress() {
      const adr = this.address;
      if(!adr.naam || !adr.straat || !adr.nummer || !adr.postnr || !adr.stad){
        this.modalError = this.$t('error.locations.incomplete');
        return;
      }
      if (adr.id) {
        this.saveEditLocation(adr);
        return;
      }
      this.saveNewLocation(adr);
    },
    async saveEditLocation(adr){
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/editLocation',adr);
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.edit');
        this.isLoading = false;
      }
    },
    async saveNewLocation(adr){
      this.modalError = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('locations/addLocation',adr);
        this.closeModal();
        this.loadLocations();
      } catch (error) {
        this.modalError = error.message || this.$t('error.locations.save');
        this.isLoading = false;
      }

    },

    closeModal(){
      this.deleteId = null;
      this.isModalActive = false;
      this.modalError = null;
    }
  }
}
</script>